import React, { ReactElement } from 'react';
import {Grid} from '@mui/material';
import useIsMobile from 'hooks/useIsMobile';

type EventInfoRowProps = {
  title: string;
  source: string | ReactElement;
};

const EventInfoRow = (props: EventInfoRowProps) => {
  const {title, source} = props;
  const isMobile = useIsMobile();

  return (
    <React.Fragment>
      <Grid item xs={12} mb={isMobile ? 0 : 1} container>
        <Grid item xs={4}>
          {title}
        </Grid>
        <Grid item xs={8}>
          {source}
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default EventInfoRow;
