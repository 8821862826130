import React from 'react';

import {Stack} from '@mui/material';
import List from 'components/list/List';
import {DateField, LinkField, ListField} from 'components/list/Fields';
import CreateButton from 'components/forms/CreateButton';
import EventForm from 'components/Event/EventForm';
import dayjs from 'dayjs';

const MyEventsPage = () => {
  const defaultValues = {
    startDate: dayjs(),
    endDate: dayjs(),
    publicDate: dayjs()
  }
  return (
    <React.Fragment>
      <List
        bulkOptions={false}
        title="My Events"
        toolbar={
          <Stack direction="row" alignItems="right" justifyContent="flex-end" gap={2}>
            <CreateButton label="Create New Event" defaultValues={defaultValues}>
              <EventForm />
            </CreateButton>
          </Stack>
        }
      >
        <LinkField source="name" label="Event" link={(record) => `/my_events/${record?.slug}`} />
        <DateField source="startDate" label="Start Date" />
        <DateField source="endDate" label="End Date" />
        <ListField source="status" label="Status" />
      </List>
    </React.Fragment>
  );
};

export default MyEventsPage;
