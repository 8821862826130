import React from 'react';

import Groups from './Groups/Groups';
import AllUsers from './AllUsers';
import {Grid} from '@mui/material';
import useIsMobile from 'hooks/useIsMobile';

const AllUsersPage = () => {
  const isMobile = useIsMobile();
  return (
    <Grid container>
      <Grid item xs={12} padding={isMobile ? 0.5 : 2}>
        <Groups />
      </Grid>
      <Grid item xs={12} padding={isMobile ? 0.5 : 2}>
        <AllUsers />
      </Grid>
    </Grid>
  );
};

export default AllUsersPage;
