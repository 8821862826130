import React from 'react';
import {
  AppBar as MuiAppBar,
  AppBarProps as MuiAppBarProps,
  Drawer as MuiDrawer,
  Box,
  CssBaseline,
  IconButton,
  Toolbar,
  styled,
  Typography,
  Button,
  Divider,
  List,
  Container,
  DrawerProps as MuiDrawerProps,
} from '@mui/material';
import {Menu as MenuIcon, ChevronLeft as ChevronLeftIcon} from '@mui/icons-material';
import {Navigate, Outlet, Link} from 'react-router-dom';
import auth from 'services/auth';
import { MainListItems, AdminListItems } from 'components/Sidebar/Sidebar';
import useIsMobile from 'hooks/useIsMobile';

const desktopDrawerWidth = 240;
const mobileDrawerWidth = 200;

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
  isMobile?: boolean;
}

interface DrawerProps extends MuiDrawerProps {
  isMobile?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open' && prop !== 'isMobile',
})<AppBarProps>(({ theme, open, isMobile }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: isMobile ? mobileDrawerWidth : desktopDrawerWidth,
    width: `calc(100% - ${isMobile ? mobileDrawerWidth : desktopDrawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer)<DrawerProps>(({ theme, open, isMobile }) => ({
  '& .MuiDrawer-paper': {
    position: 'relative',
    whiteSpace: 'nowrap',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    }),
    boxSizing: 'border-box',
    ...(!open && !isMobile && {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9)
      }
    })
  }
}));

const AdminRoute = () => {
  const isMobile = useIsMobile();

  const [open, setOpen] = React.useState(() => !window.matchMedia('(max-width:600px)').matches);

  const toggleDrawer = () => {
    setOpen((prev) => !prev);
  };

  const toggleMobileDrawer = () => {
    isMobile && setOpen((prev) => !prev);
  }

  const isAdmin = auth.isAdmin();

  if (!auth.isAuthenticated()) {
    return <Navigate to="/login" />;
  }

  return (
    <Box sx={{display: 'flex'}}>
      <CssBaseline />

      <AppBar position="fixed" open={open} isMobile={isMobile}>
        <Toolbar sx={{ pr: '24px' }}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            aria-controls="sidebar"
            aria-expanded={open}
            onClick={toggleDrawer}
            sx={{
              marginRight: '36px',
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography component="h1" variant="h6" color="inherit" noWrap sx={{ flexGrow: 1 }}>
            LiveZone - Admin Panel
          </Typography>
          <Button variant="text" color="inherit" component={Link} to="/logout">
            logout
          </Button>
        </Toolbar>
      </AppBar>

      <Drawer
        variant={isMobile ? 'temporary' : 'permanent'}
        open={open}
        isMobile={isMobile}
        onClose={toggleDrawer}
        ModalProps={{ keepMounted: true }}
        PaperProps={{
          sx: {
            width: isMobile ? mobileDrawerWidth : desktopDrawerWidth,
          },
        }}
        id="sidebar"
      >
        <Toolbar
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            px: [1],
          }}
        >
          <IconButton onClick={toggleDrawer}>
            <ChevronLeftIcon />
          </IconButton>
        </Toolbar>
        <Divider />
        <List component="nav">
          <MainListItems onClickLink={toggleMobileDrawer}/>
          <Divider sx={{ my: 1 }} />
          {isAdmin && <AdminListItems onClickLink={toggleMobileDrawer}/>}
        </List>
      </Drawer>

      {/* Main content */}
      <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[900],
          flexGrow: 1,
          height: '100vh',
          overflow: 'auto',
        }}
      >
        <Toolbar />
        <Container
          maxWidth="lg"
          sx={{
            mt: isMobile ? 0 : 1,
            mb: isMobile ? 0 : 1,
            p: isMobile ? 0.5 : 2,
          }}
        >
          <Outlet />
        </Container>
      </Box>
    </Box>
  );
};

export default AdminRoute;
