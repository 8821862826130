import React, {useState} from 'react';
import {gql, useMutation, useQuery} from '@apollo/client';
// import { xml2js } from 'xml-js'

import CardFrame from '../common/CardFrame';
import InputFile from '../common/InputFile';
import {Loader} from '../common/General';
import {useParams} from 'react-router';

const GET_EVENT_COMPETITOR = gql`
  query GetEventCompetitors($slug: String!) {
    allEventCompetitors(slug: $slug) {
      name
      siCard
    }
  }
`;

const UPLOAD_RESULTS_SI_DATA = gql`
  mutation UploadResultsSiData($slug: String!, $file: Upload!) {
    uploadResultsSiData(slug: $slug, file: $file)
  }
`;

const ImportSiDataPage = () => {
  const {eventSlug} = useParams();

  const [isSelectedFile, setIsSelectedFile] = useState(false);
  // const [siData, setSiData] = useState([])
  const [xmlFile, setXmlFile] = useState();
  // const [competitorsSiCards, setCompetitorsSiCards] = useState([])

  const {loading} = useQuery(GET_EVENT_COMPETITOR, {
    variables: {
      slug: eventSlug
    }
    // onCompleted: ({allEventCompetitors}) => {
    //   setCompetitorsSiCards(allEventCompetitors.map(competitor => competitor.siCard))
    // }
  });

  const [uploadResultsSiData] = useMutation(UPLOAD_RESULTS_SI_DATA, {
    onCompleted: (data) => {
      if (data.uploadResultsSiData) {
        console.log('Done');
      }
    }
  });

  if (loading) return <Loader loading={loading} />;

  // const handleFileChange2 = (xmlString) => {
  //   const data = xml2js(xmlString, { compact: true })
  //   if (data.ResultList && data.ResultList.ClassResult) {
  //       let runners = []
  //       data.ResultList.ClassResult.map(result => {
  //           runners = runners.concat(result.PersonResult)
  //       })
  //       runners = runners.filter(runner => runner.Result.ControlCard && competitorsSiCards.includes(parseInt(runner.Result.ControlCard._text)))

  //       setSiData(runners.map(runner => {
  //           const start = new Date(runner.Result.StartTime._text)
  //           const finish = new Date(runner.Result.FinishTime._text)
  //           const startTime = start.getTime()
  //           const siCard = parseInt(runner.Result.ControlCard._text)
  //           const controls = runner.Result.SplitTime.filter(split => split.Time).map(split => {
  //               const code = parseInt(split.ControlCode._text)
  //               const add = parseInt(split.Time._text)
  //               const time = new Date(startTime + add * 1000);
  //               return {
  //                   code,
  //                   time
  //               }
  //           })
  //           return {
  //               siCard,
  //               start,
  //               finish,
  //               controls
  //           }
  //       }))

  //   }
  // }

  const handleFileChange = (e) => {
    let file = e.target.files[0];
    if (file) {
      let extension = file.name.split('.').pop().toLowerCase();
      if (extension === 'xml') {
        setXmlFile(file);
        setIsSelectedFile(true);
      }
    }
  };

  const handleSubmit = () => {
    uploadResultsSiData({
      variables: {
        slug: eventSlug,
        file: xmlFile
      }
    });
  };

  return (
    <div className="container-fluid">
      <CardFrame title="Import SI Data">
        {!isSelectedFile && <InputFile className="btn btn-primary" onFileSelect={handleFileChange} label="Select File" />}
        {isSelectedFile && (
          <>
            <button className="btn btn-primary" onClick={handleSubmit}>
              Save
            </button>
          </>
        )}
      </CardFrame>
    </div>
  );
};

export default ImportSiDataPage;
