import React from 'react';

import {Add, DirectionsRun} from '@mui/icons-material';
import {useRecordContext} from 'components/record/useRecordContext';
import {ResourceContextProvider} from 'components/record/ResourceContext';
import {Button} from '@mui/material';
import {Link} from 'react-router-dom';
import {DateField, ListField} from 'components/list/Fields';
import List from 'components/list/List';
import useIsOrienteering from 'hooks/useIsOrienteering';

const CompetitorsSample = () => {
  const {record: event} = useRecordContext();
  const isOrienteering = useIsOrienteering();

  return (
    <ResourceContextProvider resource="EventCompetitor" params={{slug: event.slug}}>
      <List
        bulkOptions={false}
        hidePagination
        title="Competitors"
        avatar={<DirectionsRun />}
        refresh
        toolbar={
          <React.Fragment>
            {isOrienteering && <Button size="small" variant="contained" component={Link} to={`/my_events/${event.slug}/si/import`}>
              <Add /> Import SI Data XML
            </Button>}
          </React.Fragment>
        }
      >
        <ListField source="name" label="Name" />
        <DateField source="startTime" label="Start Time" format="HH:mm:ss" />
        <ListField source="subEvent.name" label="Sub Event" />
        <ListField source="siCard" label="SI Card" />
      </List>
    </ResourceContextProvider>
  );
};

export default CompetitorsSample;
