import React from 'react';
import {Add} from '@mui/icons-material';
import {Button, ButtonProps} from '@mui/material';
import ButtonDialog from 'components/forms/ButtonDialog';
import {useResourceContext} from 'components/record/ResourceContext';
import {useListContext} from 'components/list/ListContext';
import CreateDialogBody from './CreateDialogBody';
import { SingleRecord } from 'components/record/RecordContext';

const CreateButton = (props: CreateButtonProps) => {
  const {resource} = useResourceContext();
  const {refetch} = useListContext();
  const {children, callback = refetch, label = `Create ${resource}`, many, dialogTitle = `Add new ${resource}`, defaultValues, ...rest} = props;

  return (
    <ButtonDialog
      buttonLabel={label}
      startIcon={<Add />}
      {...rest}
      dialogTitle={dialogTitle}
      primaryButton={
        <Button type="submit" form={`create_${resource}`} variant="contained">
          Create
        </Button>
      }
    >
      <CreateDialogBody onSuccess={callback} many={many} defaultValues={defaultValues}>
        {children}
      </CreateDialogBody>
    </ButtonDialog>
  );
};

type CreateButtonProps = Omit<ButtonProps, 'onClick'> & {
  children: React.ReactNode;
  label?: string;
  dialogTitle?: string;
  callback?: () => void;
  many?: boolean;
  defaultValues?: SingleRecord;
};

export default CreateButton;
