import React from 'react';
import {
  Box,
  Card,
  CardHeader,
  IconButton,
  SortDirection,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  Typography,
  TableRow as MuiTableRow
} from '@mui/material';
import TableHead from './TableHead';
import {Identifier, RecordContextProvider, RecordType} from 'components/record/RecordContext';
import TableRow from './TableRow';
import {useFetch} from 'hooks/useFetchData';
import {useResourceContext} from 'components/record/ResourceContext';
import {ListContextProvider} from './ListContext';
import {Sync} from '@mui/icons-material';
import TableToolbar from './actionMenu/TableToolbar';
import BulkDelete from 'components/forms/BulkDelete';
import useIsMobile from 'hooks/useIsMobile';
import { Loader, Error } from 'components/common/General';

const List = <T extends RecordType>(props: ListProps<T>) => {
  const {
    children,
    bulkOptions = false,
    hidePagination = false,
    filters = {},
    title,
    refresh = false,
    avatar,
    onChangeSelected,
    bulkActions = <BulkDelete />,
    toolbar,
    listFilters = <React.Fragment></React.Fragment>,
    identifier = 'id'
  } = props;

  const {resource, params} = useResourceContext();
  const isMobile = useIsMobile();

  const [page, setPage] = React.useState(0);
  const [order, setOrder] = React.useState<SortDirection>('asc');
  const [orderBy, setOrderBy] = React.useState('id');
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [actionFilters, setActionFilters] = React.useState({});

  const addFilters = (filters: ListFiltersType) => {
    setActionFilters((actionFilters) => ({...actionFilters, ...filters}));
  };

  const variables = React.useMemo(
    () => ({
      ...params,
      filters: {
        ...filters,
        ...actionFilters
      },
      // orderBy, //TODO:
      // order: order === 'asc' ? 'asc' : 'desc',
      ...(!hidePagination ? {page: page + 1, size: rowsPerPage} : {})
    }),
    [page, rowsPerPage, orderBy, order, hidePagination, params, filters, actionFilters]
  );

  const {error, data, loading, refetch} = useFetch<{items: T[]; total: {count: number}}>('GET_LIST', resource, {
    variables
  });

  const list = React.useMemo(() => (data?.items || []).map((item: T) => ({...item, id: item?.[identifier] ?? Math.random()})), [data]);
  const count = React.useMemo(() => data?.total?.count || data?.items?.length || 0, [data]);

  const handleSort = (id: string) => {
    const isAsc = orderBy === id && order === 'asc';
    if (id !== '') {
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(id);
    }
  };

  const handleChangePage = (_event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  if (loading) {
    return <Loader />;
  }
  if (error) return <Error refetch={refetch}/>;

  return (
    <ListContextProvider refetch={refetch} filters={actionFilters} addFilters={addFilters}>
      <Card sx={{p: isMobile ? 0.5 : 2, mb: isMobile? 0.5 : 2, position: 'relative'}}>
        <CardHeader
          avatar={avatar}
          title={
            title && (
              <Typography component="div" variant="h5">
                {title}
              </Typography>
            )
          }
          action={
            <Box display="flex" alignItems="center">
              {refresh && (
                <IconButton onClick={() => refetch()} color="primary">
                  <Sync />
                </IconButton>
              )}
            </Box>
          }
        />
        {toolbar && <Box gap={isMobile? 0.5 : 2} display="flex" alignItems="center">
          {toolbar}
        </Box>}
        {listFilters}
        <TableToolbar onChangeSelected={onChangeSelected} bulkActions={bulkActions} />
        <TableContainer sx={{overflowX: 'auto'}}>
          <Table sx={{width: '100%'}} size="small">
            {React.createElement(
              TableHead,
              {
                bulkOptions,
                order,
                orderBy,
                rowCount: count,
                onRequestSort: handleSort,
                ids: list.map((item) => item.id)
              },
              children
            )}

            <TableBody>
              {list?.map((row) => (
                <RecordContextProvider key={row.id} value={row}>
                  <TableRow bulkOptions={bulkOptions} id={row.id}>
                    {children}
                  </TableRow>
                </RecordContextProvider>
              ))}

              {list.length === 0 && (
                <MuiTableRow>
                  <TableCell colSpan={React.Children.count(children) + (bulkOptions ? 1 : 0)} align="center">
                    No records found.
                  </TableCell>
                </MuiTableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {!hidePagination && (
          <TablePagination
            page={page}
            component="div"
            count={count}
            rowsPerPage={rowsPerPage}
            onPageChange={handleChangePage}
            rowsPerPageOptions={[25, 50, 100]}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </Card>
    </ListContextProvider>
  );
};

export default List;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ListFiltersType = any;

type ListProps<T extends RecordType> = {
  toolbar?: React.ReactElement;
  avatar?: React.ReactNode;
  title?: React.ReactNode;
  refresh?: boolean;
  children: JSX.Element | JSX.Element[];
  filters?: ListFiltersType;
  listFilters?: React.ReactNode;
  list?: T[];
  onEditClick?: (record: T) => void;
  onDeleteClick?: (record: T) => void;
  hidePagination?: boolean;
  onChangeSelected?: (selected: Identifier[]) => void;
  bulkOptions?: boolean;
  bulkActions?: React.ReactNode;
  identifier?: string;
};
