import React from 'react';
import {useQuery} from '@apollo/client';
import dateformat from 'dateformat';

import CardFrame from '../common/CardFrame';
import {GET_ALL_DEVICES} from '../../services/gqlQueries';
import {Loader} from '../common/General';

const AllDevicesPage = () => {
  const {loading, data} = useQuery(GET_ALL_DEVICES);

  if (loading) {
    return <Loader loading={loading} />;
  }

  return (
    <div className="container-fluid">
      <CardFrame title="All Devices">
        <table className="table table-hover">
          <thead>
            <tr>
              <th style={{width: '40%'}}>Device ID</th>
              <th style={{width: '10%'}}>Battery</th>
              <th style={{width: '20%'}}>Last check</th>
              <th style={{width: '10%'}}>User</th>
            </tr>
          </thead>
          <tbody>
            {data.allDevices.map((d) => (
              <tr key={d.id}>
                <td>{d.name}</td>
                <td>{d.battery}</td>
                <td>{d.last ? dateformat(new Date(d.last), 'yyyy-mm-dd HH:MM:ss') : null}</td>
                <td>{d.user && d.user.firstName}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </CardFrame>
    </div>
  );
};

export default AllDevicesPage;
