import React from 'react';
import {SubEventType} from 'components/SubEvent/SubEvents';
import ColorInput from 'components/common/color-picker/ColorInput';
import {AutocompleteInput, DateTimePickerInput, SelectInput, TextInput} from 'components/forms/Inputs';
import {useController, useWatch} from 'react-hook-form';
import {useFetch} from 'hooks/useFetchData';

type UserType = {id: number; firstName: string; lastName: string; userDevice: {imei: number; name: string}};

const CompetitorForm = (props: CompetitorFormProps) => {
  const {subEvent, devices = []} = props;
  const {data, loading} = useFetch<{items: UserType[]}>('GET_LIST', 'User');
  const userOptions = React.useMemo(() => (data?.items || []).map((item) => ({id: item.id, label: `${item.firstName} ${item.lastName}`})), [data]);
  
  const userId = useWatch({name: 'userId'});
  
  const user = React.useMemo(() => data?.items.find((item) => item.id === userId), [data, userId]);
  
  const devicesOptions = React.useMemo(() => {
    return user?.userDevice ? [{id: user.userDevice.imei, label: user.userDevice.name}, ...devices] : devices;
  }, [user]);

  React.useEffect(() => {
    if (user) {
      onChangeName(`${user.firstName} ${user.lastName}`);
      if (subEvent.useGPS) {
        onChangeDeviceId(user.userDevice.imei);
      }
    }
  }, [user]);

  const {
    field: {onChange: onChangeName}
  } = useController({name: 'name'});

  const {
    field: {onChange: onChangeDeviceId}
  } = useController({name: 'deviceId'});

  return (
    <React.Fragment>
      <AutocompleteInput fullWidth loading={loading} name="userId" label="User" options={userOptions} />

      <TextInput required fullWidth label="Name" name="name" autoFocus disabled={!!user} />
      <TextInput fullWidth label="Short Name" name="shortName" />
      <DateTimePickerInput fullWidth required format="YYYY-MM-DD HH:mm" name="startTime" label="Start Time" />
      {subEvent.useGPS && <ColorInput fullWidth label="Color" name="color" />}
      {subEvent.useGPS && !loading && <SelectInput fullWidth required name="deviceId" label="Device" options={devicesOptions || []} />}
      {subEvent.event.settings.useSI && <TextInput fullWidth required name="siCard" label="SI Card" />}
    </React.Fragment>
  );
};

export default CompetitorForm;

type CompetitorFormProps = {
  subEvent: SubEventType;
  devices: {
    id: string | number;
    label: string | number;
    disabled?: boolean;
  }[];
};
