import React from 'react'
import { Box, Button, CircularProgress, Typography } from '@mui/material'

export const Loader = () => (
    <Box display="flex" justifyContent="center" alignItems="center" height="200px">
      <CircularProgress />
    </Box>
  );

export const Error = ({refetch}: {refetch?: () => void}) => (
  <Box display="flex" justifyContent="center" alignItems="center" height="50vh" flexDirection="column">
      <Typography color="error">Failed to load data.</Typography>
      {refetch && <Button onClick={refetch}>Retry</Button>}
    </Box>
)