import React from 'react';

import {Grid} from '@mui/material';
import {useWatch} from 'react-hook-form';
import {DateTimePickerInput, SelectInput, TextInput, SwitchInput} from 'components/forms/Inputs';
import InputLocation from './InputLocation';
import useIsMobile from 'hooks/useIsMobile';

const eventCategoryOptions = [
  {id: 'test', label: 'Test'},
  {id: 'orienteering', label: 'Orienteering'},
  {id: 'running', label: 'Running'}
];

// const delayOptions = [10, 20, 30, 40, 50, 60].map((x) => ({id: x, label: x}));
// const gpsIntervalOptions = [1, 2, 3, 5, 10].map((x) => ({id: x, label: x}));

const EventForm = () => {
  const isOpen = useWatch({name: 'isOpen'});
  const isMobile = useIsMobile();

  return (
    <Grid container>
      <Grid item xs={12} md={6} padding={isMobile? 0.5 : 2}>
        <TextInput required fullWidth label="Name" name="name" autoFocus />
        <TextInput fullWidth label="Abbreviation" name="slug" />
        <SelectInput fullWidth required name="category" label="Category" options={eventCategoryOptions} />
        <TextInput fullWidth label="Description" name="description" />
        <TextInput fullWidth label="Web Page" name="web" />
      </Grid>
      <Grid item xs={12} md={6} padding={isMobile? 0.5 : 2}>
        <DateTimePickerInput fullWidth required format="YYYY-MM-DD HH:mm" name="startDate" label="Start Date" />
        <DateTimePickerInput fullWidth required format="YYYY-MM-DD HH:mm" name="endDate" label="End Date" />
        <SwitchInput sx={{width: '100%'}} label="Is Open" name="isOpen" />
        {!isOpen && <DateTimePickerInput fullWidth required format="YYYY-MM-DD HH:mm" name="publicDate" label="Published" />}
        <SwitchInput sx={{width: '100%'}} name="hidden" label="Hidden" />
        {/* <SwitchInput sx={{width: '100%'}} name="canEnter" label="Can competitors enter for events" />
        <SwitchInput sx={{width: '100%'}} name="analytic" label="Analytic" />
        <SelectInput fullWidth required name="delay" label="Delay" options={delayOptions} />
        <SelectInput fullWidth required name="gpsInterval" label="GPS Interval" options={gpsIntervalOptions} /> */}
      </Grid>
      <Grid item xs={12} padding={isMobile? 0.5 : 2}>
        <InputLocation label="Location" />
      </Grid>
    </Grid>
  );
};

export default EventForm;
