import React, {ReactNode} from 'react';
import {RecordContextProvider, RecordType} from './RecordContext';
import {useFetch} from 'hooks/useFetchData';
import {useResourceContext} from './ResourceContext';
import {useParams} from 'react-router';
import { Loader } from 'components/common/General';

const ResourceRecordContextProvider = <T extends RecordType>(props: ResourceRecordContextProviderProps) => {
  const {resource} = useResourceContext();
  const params = useParams();
  const {param, id = params[param!], ...rest} = props;
  const {data, loading, error, refetch} = useFetch<{item: T}>('GET_ONE', resource, {variables: {id}});

  if (loading) {
    return <Loader/>;
  }
  if (error) {
    return <>Some error happen</>;
  }

  return <RecordContextProvider refresh={refetch} {...rest} value={data!.item!} id={data!.item![param!] || id} />;
};

export default ResourceRecordContextProvider;

export interface ResourceRecordContextProviderProps {
  param?: string;
  id?: string;
  children: ReactNode;  
}
